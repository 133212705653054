<template>
  <div>
    <Header class="page-anim1" />

    <section>
      <div class="container">
        <div
          class="flex-between mt-3-desktop mt-2-mobile mt-3-tablet animated fadeInLeft"
        >
          <h1 class="title color-primary">Onde Comprar</h1>

          <select
            v-model="selectedState"
            @change="getStores"
            class="selectState"
          >
            <option value="">Filtre por um Estado</option>
            <option value="Acre">Acre</option>
            <option value="Alagoas">Alagoas</option>
            <option value="Amapá">Amapá</option>
            <option value="Amazonas">Amazonas</option>
            <option value="Bahia">Bahia</option>
            <option value="Ceará">Ceará</option>
            <option value="Distrito Federal">Distrito Federal</option>
            <option value="Espírito Santo">Espírito Santo</option>
            <option value="Goiás">Goiás</option>
            <option value="Maranhão">Maranhão</option>
            <option value="Mato Grosso">Mato Grosso</option>
            <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
            <option value="Pará">Pará</option>
            <option value="Minas Gerais">Minas Gerais</option>
            <option value="Paraíba">Paraíba</option>
            <option value="Paraná">Paraná</option>
            <option value="Pernambuco">Pernambuco</option>
            <option value="Piauí">Piauí</option>
            <option value="Rio de Janeiro">Rio de Janeiro</option>
            <option value="Rio Grande do Norte">Rio Grande do Norte</option>
            <option value="Rio Grande do Sul">Rio Grande do Sul</option>
            <option value="Rondônia">Rondônia</option>
            <option value="Roraima">Roraima</option>
            <option value="Santa Catarina">Santa Catarina</option>
            <option value="São Paulo">São Paulo</option>
            <option value="Sergipe">Sergipe</option>
            <option value="Tocantins">Tocantins</option>
          </select>
        </div>

        <p
          v-show="!loading && stores.length < 1"
          class="text-center mt-3 warning red"
        >
          Ops, sem locais de compra por aqui.
        </p>

        <div
          v-show="loading"
          class="flex-center desktop12 tablet12 mobile12 mt-3"
        >
          <div class="loading2"></div>
        </div>

        <div v-show="!loading && stores.length > 0" class="stores">
          <div
            v-for="(store, index) in stores"
            :key="store.id"
            class="card animated fadeIn"
            :style="`animation-delay: ${300 + index * 150}ms`"
          >
            <div class="title-holder">
              <div class="store">
                <Store :fillColor="'var(--yellow2)'" :size="28" />
                <span class="name">{{ store.name }}</span>
              </div>
              <div class="state">
                <MapMarkerRadius :fillColor="'var(--yellow2)'" :size="28" />
                <span class="name">{{ store.state }}</span>
              </div>
            </div>
            <p v-show="store.address" class="desc">{{ store.address }}</p>
            <div class="bottom-holder">
              <div class="phone">
                <div class="phone-item">
                  Ligar:
                  <a :href="`tel:${store.phone}`">{{ store.phone }}</a>
                </div>
                <div class="phone-item" v-if="store.email.length > 5">
                  Email:
                  <a :href="`mailto:${store.email}`">{{ store.email }}</a>
                </div>
              </div>
              <a
                v-show="store.map.length > 0"
                class="map-button"
                noreferrer
                :href="store.map"
                target="_blank"
              >
                Ver no mapa
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import PostCard from "../components/PostCard";
import MapMarkerRadius from "vue-material-design-icons/MapMarkerRadius.vue";
import Store from "vue-material-design-icons/Store.vue";

export default {
  name: "Home",
  components: {
    Header,
    PostCard,
    MapMarkerRadius,
    Store,
  },
  data() {
    return {
      loading: true,
      stores: [],
      selectedState: "",
    };
  },
  created() {
    this.getStores();
  },
  methods: {
    getStores() {
      http
        .get(`store/list?take=-1&orderBy=timestamp&state=${this.selectedState}`)
        .then((response) => {
          this.stores = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.phone-item {
  padding: 4px;
}

.map-button {
  border: 1px solid var(--secondary);
  border-radius: 0.5em;
  display: grid;
  place-items: center;
  padding: 1em 2em;
  width: 100%;
  margin-top: 10px;
}

.selectState {
  position: relative;
  width: 12%;
  height: 28px;
  color: var(--dark5);
  font-size: 1.05em;
  border-bottom: 1px solid var(--primary);
  margin-left: auto;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #003da7;
    outline: 1px solid slategrey;
  }
}
.stores {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  margin-top: 30px;
  margin-bottom: 45px;
}

.card {
  position: relative;
  width: 100%;
  padding: 15px;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 6px;

  .title-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .store {
      display: flex;
      align-items: center;
    }
    .state {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .name {
      font-size: 15px;
      margin-left: 5px;
    }
  }
  h1 {
    font-family: fontLight;
    font-size: 1.2em;
    color: var(--dark4);
  }
  p.desc {
    font-family: fontLight;
    font-size: 1.05em;
    color: var(--dark5);
    margin-top: 6px;
    line-height: 1.25em;
    height: 3.75em;
    overflow: hidden;
  }
  .bottom-holder {
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 1.05em;
      color: var(--secondary);
    }
  }
}

@media screen and (min-width: 1160px) {
  .stores {
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  }

  .phone-item {
    padding: 2px;
  }

  .map-button {
    width: initial;
    margin-bottom: 0px;
  }

  .bottom-holder {
    display: flex;
  }
}
</style>
